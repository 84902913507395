<template>
  <div class="OneclickwritingDilets">
    <!-- 搜索 -->

    <div class="head">
      <img class="icon" @click="back" src="../../assets/icon9.png" alt="" />
      <div class="back" @click="back">返回</div>
      <div class="search_box">
        <input
          class="inp"
          type="text"
          placeholder="成语AI-专业内容创作辅助"
          v-model="searchValues"
          @keyup.enter="searchs"
        />
        <div class="search_btn" @click="searchs">
          <img :src="require('assets/addwen.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="imgs">
        <img src="../../assets/story.png" alt="" />
      </div>
      <div class="title">
        <h3>{{ valobj.title }}</h3>
        <div class="pase">
          <span>{{ pase }}</span> 券
        </div>
      </div>
      <div class="shopping" @click="buyAll">购买</div>
    </div>
    <div class="context">
      <!-- 排序 -->
      <div class="list-title">
        <span class="infocompileIndex_btn"
          ><i
            class="introduce-checkBox-icon"
            :class="checked ? 'checkBox-checked' : ''"
            @click="handleCheckAllChange"
          ></i
          >全选</span
        >
        <div
          class="list-title-item"
          v-for="(item, index) in sortArr"
          :key="index"
          style="cursor: pointer"
          @click="sortClick(item)"
        >
          <div
            class="list-title-item-text"
            :class="item.colorSwitch == true ? 'fontColor' : ''"
          >
            {{ item.title }}
          </div>
          <i
            class="list-title-icon up"
            :class="item.sort == 'asc' ? 'up' : 'down'"
          ></i>
        </div>
        <div class="adds" @click="addShopCart1">批量加入购物车</div>
      </div>
      <!-- 内容 -->
      <div class="texts">
        <div class="item" v-for="(item, idx) in dataList" :key="idx">
          <span
            @click="handleChecked(item, idx)"
            class="introduce-checkBox-icon"
            :class="item.ischecked == true ? 'checkBox-checked' : ''"
          ></span>
          <div class="idx">{{ idx + 1 }}</div>
          <div
            class="titl"
            :class="item.isSell == 1 ? 'cl' : ''"
            @click="caseDetails(item)"
          >
            {{ item.title }}
          </div>
          <!-- <div class="goshopp">{{ item.price ? item.price : 0 }}券</div> -->
          <div class="goshopp" :class="item.isSell == 2 ? 'gocl' : ''">
            {{
              item.isSell == 0 || item.isSell == 1
                ? item.price
                : item.discountPrice
            }}券
          </div>
          <div class="time" v-if="item.createTime">
            <img src="../../assets/times.png" alt="" />
            {{ item.createTime.split(" ")[0] }}
          </div>
          <div class="operation">
            <div
              class="add1"
              @click="addShopCart(item.textMd5)"
              v-if="item.isSell == 0"
            >
              <img src="../../assets/shopp.png" alt="" />
            </div>
            <div class="go" @click="buy(item)" v-if="item.isSell == 0">
              立即购买
            </div>
          </div>
          <div class="operation">
            <div
              class="add1"
              @click="addShopCart(item.textMd5)"
              v-if="item.isSell == 2 && item.isPurchase != 1"
            >
              <img src="../../assets/shopp1.png" alt="" />
            </div>
            <div
              class="go1"
              @click="buy(item)"
              v-if="item.isSell == 2 && item.isPurchase != 1"
            >
              立即购买
            </div>
          </div>
          <!-- <div
            class="add"
            @click="addShopCart(item.textMd5)"
            v-if="!item.isSell"
          >
            加入购物车
          </div> -->
          <div class="yadd" v-if="item.isSell == 1 || item.isPurchase == 1">
            {{ item.isPurchase == 1 ? "已购买" : "已出售" }}
          </div>
        </div>
        <el-pagination
          class="pagination"
          background
          @current-change="currentChange"
          layout="prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
    <transition name="shop_cart">
      <img
        class="add_img"
        v-if="addShow"
        src="../../assets/story1.png"
        alt=""
      />
    </transition>
    <transition name="shop_cart1">
      <img
        class="add_img"
        v-if="addShow1"
        src="../../assets/story1.png"
        alt=""
      />
    </transition>
    <div class="shoppingcard" @click="shoppingcard">
      <div class="box">{{ num > 99 ? `${num}+` : num }}</div>
    </div>
    <!-- 检验成语券弹框 -->
    <ContinuationTips
      :Tipsmessage="currentTips"
      :isShow="isShow"
      :number="number"
      ref="ContinuationTips"
    />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  caseSearch,
  caseDetail,
  saveGoshopp,
  getList,
  submit,
  buyCase,
  checkIdiomCoupon,
  readingCasePageList,
  findCasePrice,
} from "api/Continuation";
import { settings } from "api/service";
import qs from "qs";
import ContinuationTips from "components/ContinuationTips";
export default {
  name: "OneclickwritingDilets",
  //import引入的组件需要注入到对象中才能使用
  components: { ContinuationTips },
  props: {
    valobj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //这里存放数据
    return {
      currentTips: "",
      // 显示次数不足弹框
      isShow: false,
      number: 0,
      searchValues: "",
      sortArr: [
        {
          title: "时间",
          sort: "desc",
          id: 1,
          type: "create_time",
          colorSwitch: true,
        },
        // {
        //   title: '热度',
        //   sort: 'asc',
        //   id: 2,
        //   colorSwitch: false,
        // },
        {
          title: "价格",
          type: "price",
          sort: "asc",
          id: 2,
        },
      ],
      page: 1,
      totalNum: 0,
      textMd5s: "",
      pase: 0,
      pageUuid: "",
      sortType: "asc",
      sortVal: "create_time",
      // 勾选内容
      checkedList: [],
      checkedList1: [],

      checked: false,
      // 加入购物车效果
      addShow: false,
      addShow1: false,
      dataList: [],
      num: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 查询整本书的价格
    async findCasePrices() {
      console.log(this.valobj);
      let params = {
        labelId: this.valobj.labelId,
      };
      let data = await findCasePrice(qs.stringify(params));
      this.pase = data.data;
    },
    // 分页
    currentChange(val) {
      this.page = val;
      this.caseSearchs();
    },
    // 获取购物车数据
    async getLists() {
      let data = await getList();
      this.num = 0;
      if (data.code == 200) {
        data.data.forEach((item) => {
          this.num += item?.esCases.length;
        });
      }
    },
    // 查看详情
    async caseDetails(val) {
      if (val.id) {
        this.$router.push({
          path: "/Dilets",
          query: { id: 1, showlls: 1, vals: val },
        });
      } else {
        let res = {
          id: this.valobj.id,

          obj: {
            textMd5: val.textMd5,
            labelId: this.valobj.labelId,
          },
        };
        this.$router.push({
          path: "/Dilets",
          query: { id: 1, da: res, showlls: 2 },
        });
      }
    },
    // 去购物车
    shoppingcard() {
      this.$router.push({
        path: "/ShoppingCart",
        query: { id: 1, num: this.valobj.price, labelId: this.valobj.labelId },
      });
    },
    // 搜索
    searchs() {
      if (this.valobj.author) {
        this.readingCasePageLists();
      } else {
        this.caseSearchs();
      }
    },
    // 获取详情
    async caseSearchs() {
      let res = {
        // labelId: 'FZalgshi0001-001-0018',
        labelId: this.valobj.labelId,
        keyWords: this.searchValues,
        sort: this.sortVal,
        sortType: this.sortType,
        page: this.page,
        pageUuid: this.pageUuid,
      };
      let data = await caseSearch(qs.stringify(res));
      if (data.code == 200) {
        data.data.data.forEach((item) => {
          item.ischecked = false;
        });
        this.dataList = data.data.data;
        this.pageUuid = data.data.page_uuid;
        this.totalNum = data.data.totalNum;
        // this.pase = this.valobj.price * data.data.totalNum
      }
    },
    // 获取详情
    async readingCasePageLists() {
      let params = {
        page: this.page,
        limit: 12,
        title: this.searchValues,
        textMd5: this.valobj.textMd5,
      };
      let data = await readingCasePageList(params);
      if (data.code == 200) {
        data.data.cases.forEach((item) => {
          item.ischecked = false;
        });
        this.dataList = data.data.cases;
        // this.pageUuid = data.data.page_uuid
        this.totalNum = data.data.total;
        // this.pase = this.valobj.price * data.data.totalNum
      }
    },
    // 加入购物车
    async addShopCart(val) {
      let params = [
        {
          goods: this.valobj.id,
          options: val,
        },
      ];
      let data = await saveGoshopp(params);
      if (data.code == 200) {
        this.addShow = true; //
        setTimeout(() => {
          this.addShow = false;
        }, 1000);
        this.$message({
          type: "success",
          message: "加入成功",
        });
      }
      this.getLists();
    },
    async submits(val) {
      const params = [
        {
          caseId: this.valobj.id,
          md5: this.textMd5s,
        },
      ];
      let data = await submit(params);
      if (data.code == 200) {
        this.$message.success("购买成功");
        this.caseSearchs();
        this.findCasePrices();
        this.$confirm("购买成功,是否前往购买列表？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",

          center: true,
        })
          .then(() => {
            this.$bus.$emit("showBuy");
          })
          .catch(() => {});
      } else if (data.code == 9009) {
        this.$message.error("已售出，购买失败！");
      }
    },
    async buy(val) {
      this.currentTips = "";
      this.textMd5s = [val.textMd5];
      let res = await checkIdiomCoupon({
        type: 10,
        num: val.isSell == 0 ? val.price : val.discountPrice,
      });
      if (res.code == 200) {
        let data = await settings();
        if (data.data.aiImg == 1) {
          this.isShow = false;
          this.submits();
        } else {
          this.isShow = true;
          this.$refs.ContinuationTips.numbers(
            val.isSell == 0 ? val.price : val.discountPrice
          );
          // this.$bus.$emit('numbers', val.price)
        }
      } else if (res.code == 9008) {
        this.currentTips = res.message;
        this.$refs.ContinuationTips.numbers("0.1997");
        // this.$bus.$emit('numbers', '0.1997')
        this.isShow = true;
        // this.$confirm(res.message, '提示', {
        //   confirmButtonText: '去购买',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // })
        //   .then(() => {
        //     // window.open("https://www.writemall.com/details/973");
        //     this.$router.push('/details/973')
        //   })
        //   .catch(() => {})
      }
    },
    async buyAlls() {
      const params = {
        caseId: this.valobj.id,
      };
      const res = await buyCase(qs.stringify(params));
      if (res.code == 200) {
        this.$message.success("购买成功");
        this.caseSearchs();
        this.findCasePrices();
        this.$confirm("购买成功,是否前往购买列表？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",

          center: true,
        })
          .then(() => {
            this.$bus.$emit("showBuy");
          })
          .catch(() => {});
      } else if (res.code == 9009) {
        this.$message.error("已售出，购买失败！");
      }
    },
    async buyAll() {
      let res = await checkIdiomCoupon({
        type: 10,
        num: this.pase,
      });
      if (res.code == 200) {
        this.currentTips = "";
        let data = await settings();
        if (data.data.aiImg == 1) {
          this.isShow = false;
          this.buyAlls();
        } else {
          this.isShow = true;
          // this.$bus.$emit('numbers', this.pase)
          this.$refs.ContinuationTips.numbers(this.pase);
        }
      } else if (res.code == 9008) {
        this.currentTips = res.message;
        // this.$bus.$emit('numbers', '0.1997')
        this.$refs.ContinuationTips.numbers("0.1997");
        this.isShow = true;
      }
    },
    async addShopCart1() {
      console.log(this.checkedList1);
      if (this.checkedList1.length < 1) {
        this.$message({
          type: "error",
          message: "请选择案例故事",
        });
        return;
      }

      let data = await saveGoshopp(this.checkedList1);
      if (data.code == 200) {
        this.addShow1 = true; //
        setTimeout(() => {
          this.addShow1 = false;
        }, 1000);
        this.$message({
          type: "success",
          message: "加入成功",
        });
      }
      this.getLists();
    },
    // 点击勾选

    handleChecked(val, i) {
      val.ischecked = !val.ischecked;
      if (this.checkedList.indexOf(val.textMd5) == -1) {
        this.checkedList.push(val.textMd5);
        this.checkedList1.push({
          goods: this.valobj.id,
          options: val.textMd5,
        });
      } else {
        this.checkedList.splice(i, 1);
        this.checkedList1.splice(i, 1);
        console.log(this.checkedList1);
      }
    },
    // 全选
    handleCheckAllChange() {
      this.checked = !this.checked;
      if (this.checked) {
        this.dataList.forEach((item, i) => {
          item.ischecked = this.checked;
          this.checkedList.push(item.textMd5);
          this.checkedList1.push({
            goods: this.valobj.id,
            options: item.textMd5,
          });
        });
      } else {
        this.dataList.forEach((item, i) => {
          item.ischecked = this.checked;
          this.checkedList = [];
          this.checkedList1 = [];
        });
      }
    },
    // 排序
    sortClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      if (item.sort == "desc") {
        item.sort = "asc";
      } else {
        item.sort = "desc";
      }
      this.sortType = item.sort;
      this.sortVal = item.type;
      this.caseSearchs();
    },
    back() {
      this.$parent.shodilets = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getLists();
    this.findCasePrices();
    if (this.valobj.author) {
      this.readingCasePageLists();
    } else {
      this.caseSearchs();
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.OneclickwritingDilets {
  position: relative;
  .add_img {
    position: absolute;
    right: 0;
    bottom: 150px;

    width: 30px;
    z-index: 999;
  }
  .shoppingcard {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 150px;
    width: 51px;
    height: 51px;
    background: url("../../assets/shoppindcard.png") no-repeat;
    background-size: 100% 100%;
    .box {
      width: 100%;
      text-align: right;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ff6500;
    }
  }
  // 搜索
  .head {
    display: flex;
    align-items: center;
    padding: 16px 54px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;

    color: #999999;
    .icon {
      width: 6px;
      height: 11px;
      margin-right: 11px;
    }
  }
  .banner {
    position: relative;
    width: 100%;
    height: 88px;
    background: #fffaf6;
    padding: 15px 65px 16px 59px;
    box-sizing: border-box;
    display: flex;
    .title {
      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin: 12px 14px;
      }
      .pase {
        font-size: 12px;
        margin-left: 16px;
        color: #eb441e;
        span {
          font-size: 16px;
          font-weight: bold;
          color: #eb441e;
        }
      }
    }
    .shopping {
      cursor: pointer;
      position: absolute;
      right: 65px;
      top: calc(50% - 19px);
      width: 80px;
      height: 38px;
      border: 1px solid #ff6500;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      color: #ff6500;
      line-height: 38px;
      text-align: center;
    }
    .imgs {
      width: 57px;
      height: 57px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      padding: 5px;
      box-sizing: border-box;
      img {
        // height: 100%;
        width: 100%;
      }
    }
  }
  .context {
    padding: 21px 65px;
    min-height: 750px;
    position: relative;
    box-sizing: border-box;
    .list-title {
      display: flex;
      align-items: center;
      // padding: 20px 0px 0 0px;
      .adds {
        cursor: pointer;

        padding: 11px 17px;
        background: #ffffff;
        border: 1px solid #d6e5ff;
        border-radius: 3px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4587ff;
        &:hover {
          background-color: #f3f7ff;
        }
      }
      #LangeSelectNotes {
        margin-right: 25px;
      }
      &-item {
        display: flex;
        align-items: center;
        margin-right: 25px;
        .list-title-item-text {
          font-size: 14px;

          font-weight: 500;
          color: #333333;
        }
        .list-title-icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
          margin: 21px 6px;
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon.png"
          );
        }
        .up {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue-up.png"
          );
        }
        .down {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue.png"
          );
        }
        .fontColor {
          color: #ff6900;
        }
      }
      .infocompileIndex_btn {
        margin-right: 20px;
        display: flex;
        align-items: center;
        .introduce-checkBox-icon {
          cursor: pointer;
          display: inline-block;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url("../../assets/gouxu1.png");
          margin-right: 5px;
        }
        .checkBox-checked {
          background-image: url("../../assets/gouxu.png");
        }
      }
    }
    .texts {
      width: 100%;

      .pagination {
        // width: 100%;
        margin: 30px auto;
        // position: absolute;
        // bottom: 30px;
        text-align: center;
        ::v-deep .active {
          background-color: #ff6900;
          color: #fff;
        }
      }
      .introduce-checkBox-icon {
        cursor: pointer;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-bottom: 2px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../assets/gouxu1.png");
        margin-right: 5px;
      }
      .checkBox-checked {
        background-image: url("../../assets/gouxu.png");
      }
      .item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 13px 0;
        font-size: 14px;
        img {
          width: 16px;
          height: 15px;
          margin-right: 15px;
        }
        .idx {
          width: 30px;
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }

        .titl {
          cursor: pointer;
          font-weight: 400;
          color: #000000;
          width: 200px;
          overflow: hidden; //超出隐藏
          white-space: nowrap; //不折行
          text-overflow: ellipsis; //溢出显示省略号
        }
        .cl {
          color: #666;
        }
        .goshopp {
          cursor: pointer;
          width: 41px;
          color: #ff6900;

          margin-right: 20px;
        }
        .gocl {
          color: #4587ff;
        }
        .time {
          width: 200px;
          text-align: right;
          display: flex;
          align-items: center;
          padding-left: 5px;
          box-sizing: border-box;
          // align-items: center;
          color: #999999;
          img {
            width: 12px;
            height: 12px;
            margin-right: 2px;
          }
        }
        .go {
          cursor: pointer;

          // margin-right: 20px;
          // padding: 11px 17px;
          width: 80px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #ffe1cd;
          border-radius: 0px 3px 3px 0;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff6500;
          &:hover {
            background-color: #fffaf3;
          }
        }
        .go1 {
          cursor: pointer;

          // margin-right: 20px;
          // padding: 11px 17px;
          width: 80px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #bbd3ff;
          border-radius: 0px 3px 3px 0;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4587ff;
          &:hover {
            background-color: #f3f7ff;
          }
        }
        .yadd {
          position: absolute;
          right: 0;
          height: 30px;
          text-align: center;
          line-height: 30px;
          padding: 0 12px;
          background: #ffffff;
          border: 1px solid #ff8a83;

          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff8a83;
        }
        .operation {
          position: absolute;
          right: 0px;
          display: flex;
        }
        .add1 {
          cursor: pointer;
          margin-right: 10px;
          img {
            width: 35px;
            height: 35px;
            margin-right: 0;
            margin-top: 1px;
          }
        }
        .add {
          cursor: pointer;
          position: absolute;
          right: 0;
          padding: 11px 17px;
          background: #ffffff;
          border: 1px solid #d6e5ff;
          border-radius: 3px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4587ff;
          &:hover {
            background-color: #f3f7ff;
          }
        }
      }
    }
  }
  .search_box {
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #ff6900;
    width: 539px;
    margin: 0 auto;
    .inp {
      height: 26px;
      border-left: 1px solid #e4e4e4;
      padding-left: 20px;
      font-size: $font-size-medium;
      font-family: PingFang SC;
      font-weight: 500;
      color: $color-text-gray;
      flex: 1;
    }
    .search_btn {
      height: 40px;
      padding: 0 10px;
      background: #ff6900;
      @include flex-center();
      // border-radius: 0 5px 5px 0;
      cursor: pointer;
      > img:first-child {
        width: 52px;
        height: 22px;
        margin: 0 10px;
      }
    }
  }
}
</style>
<style scoped>
.transition_test {
  text-align: left;
  padding-bottom: 300px;
}
.shop_cart {
  position: absolute;
  right: 0;
  padding: 20px;
}

.shop_cart .add_img {
  width: 100px;
  position: absolute;
  left: 0px;
  top: -160px;
}

/* 动画效果实现 */
/* @keyframes bounce-in {
    0% {
      transform: scale(1);
      transform: translate(-610px, 100px);
    }
    100% {
      transform: scale(.5);
      transform: translate(0px, 0px);
    }
  }
  .shop_cart-enter-active {
    animation: bounce-in 1s;
  } */

/* CSS类名实现 */
.shop_cart-enter-active {
  transition: all 1s ease-out;
}
.shop_cart-enter {
  opacity: 0;
  transform: scale(0.5);
  transform: translate(-40px, -300px);
}
.shop_cart1-enter-active {
  transition: all 1s ease-out;
}
.shop_cart1-enter {
  opacity: 0;
  transform: scale(0.5);
  transform: translate(-500px, -500px);
}
</style>
